import { forEach } from "@olmokit/dom";
import { globalData } from "@olmokit/core/data";

export function dlNewsletter() {
    dataLayer.push({
        event: 'newsletter_signup',
        form: "Form Newsletter"
    });
}

export function dlSignUp() {
    dataLayer.push({
        event: 'sign_up'
    });
}

/**
 * Riscrivere la risposta del wishlist in modo da passare i dati al datalayer
 */
export function dlAddWishlist(data) {
    let orderItem = [];

    forEach(data['items'], (item) => {
        let singleItem = {
            item_id: item['id'],
            item_name: item['productdbname'],
            coupon: "",
            discount: 0,
            index: parseInt(item['id']),
            item_brand: "Ceramiche Bucci",
            // item_category: "Apparel", aggiungere la category quando pubblichi la repo
            item_variant: item['version']['name'],
            price: parseFloat(item['singleprice']),
            quantity: item['quantity']
        };
        orderItem.push(singleItem);
    });

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "view_cart",
        ecommerce: {
            currency: "EUR",
            value: parseFloat(data['subtotal']),
            coupon: "",
            items: orderItem
        }
    });
}

export function dlViewCart(data) {

    let orderItem = [];

    if(typeof data['items'] == 'array'){

        forEach(data['items'], (item) => {
            let singleItem = {
                item_id: item['id'],
                item_name: item['productdbname'],
                coupon: "",
                discount: 0,
                index: parseInt(item['id']),
                item_brand: "Ceramiche Bucci",
                // item_category: "Apparel", aggiungere la category quando pubblichi la repo
                item_variant: item['version']['name'],
                price: parseFloat(item['singleprice']),
                quantity: item['quantity']
            };
            orderItem.push(singleItem);
        });

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            event: "view_cart",
            ecommerce: {
                currency: "EUR",
                value: parseFloat(data['subtotal']),
                coupon: "",
                items: orderItem
            }
        });

    }
}

export function dlSelectItem(property, name, category, price, id, param) {

    const product = globalData.route;
    const properties = JSON.parse(property);
    let idparam = '';
    let variant = '';

    if (param) {
        const p = param.split('?');
        const type = p[p.length - 1].split('=');
        idparam = type[1];
        if (idparam != '') {
            forEach(properties, (prop) => {
                if (prop['id'] == idparam) {
                    variant = prop['name']
                }
            });
        }
    }

    let singleItem = [{
        item_id: id,
        item_name: name,
        coupon: "",
        discount: 0,
        index: id,
        item_brand: "Ceramiche Bucci",
        item_category: category,
        item_variant: variant,
        item_list_id: product,
        item_list_name: product,
        price: parseFloat(price),
    }];

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: 'select_item',
        ecommerce: {
            currency: "EUR",
            value: parseFloat(price),
            item_list_id: product,
            item_list_name: product,
            items: singleItem,
        }
    });

}

export function dlViewItem(data, params) {

    let variant = '';
    if (params['color']) {
        forEach(data['properties'], (prop) => {
            if (prop['id'] == params['color']) {
                variant = prop['name']
            }
        });
    }

    let singleItem = [{
        item_id: data['id'],
        item_name: data['name'],
        coupon: "",
        discount: 0,
        index: data['id'],
        item_brand: "Ceramiche Bucci",
        item_category: data['category']['name'],
        item_variant: variant,
        price: parseFloat(data['price']),
    }];

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: 'view_item',
        ecommerce: {
            currency: "EUR",
            value: parseFloat(data['price']),
            items: singleItem
        }
    });

}

export function dlAddCart(response, type = 'addButton') {

    const { data } = response;

    let item_variant;
    if (type == 'addButton') {
        item_variant = data['current_item']['version'];
    } else {
        item_variant = data['current_item']['name'];
    }

    let singleItem = [{
        item_id: data['current_item']['id'],
        item_name: data['current_product']['name'],
        coupon: "",
        discount: 0,
        index: parseInt(data['current_item']['id']),
        item_brand: "Ceramiche Bucci",
        // item_category: "Apparel", aggiungere la category quando pubblichi la repo
        item_variant: item_variant,
        price: parseFloat(data['current_product']['price']),
        quantity: 1
    }];

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
            currency: "EUR",
            value: parseFloat(data['current_product']['price']),
            items: singleItem
        }
    });

}

/**
 * Riscrivere la risposta del remove in modo da passare i dati al datalayer
 * ma solo per il delete item from cart
 */
export function dlRemoveCart(response, type = 'addButton') {

    const { data } = response;

    let item_variant;
    if (type == 'addButton') {
        item_variant = data['current_item']['version'];
    } else {
        item_variant = data['current_item']['name'];
    }

    let singleItem = [{
        item_id: data['current_item']['id'],
        item_name: data['current_item']['name'],
        coupon: "",
        discount: 0,
        index: parseInt(data['current_item']['id']),
        item_brand: "Ceramiche Bucci",
        // item_category: "Apparel", aggiungere la category quando pubblichi la repo
        item_variant: item_variant,
        price: parseFloat(data['current_product']['price']),
        quantity: 1
    }];

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            currency: "EUR",
            value: parseFloat(data['current_product']['price']),
            items: singleItem
        }
    });

}

export function dlOrderBegin(data) {

    let orderItem = [];

    forEach(data['items'], (item) => {
        let singleItem = {
            item_id: item['id'],
            item_name: item['productdbname'],
            coupon: "",
            discount: 0,
            index: parseInt(item['id']),
            item_brand: "Ceramiche Bucci",
            // item_category: "Apparel", aggiungere la category quando pubblichi la repo
            item_variant: item['version']['name'],
            price: parseFloat(item['singleprice']),
            quantity: item['quantity']
        };
        orderItem.push(singleItem);
    });

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
            currency: "EUR",
            value: parseFloat(data['subtotal']),
            coupon: "",
            items: orderItem
        }
    });

}

export function dlOrderPayment(data) {

    let orderItem = [];

    forEach(data['items'], (item) => {
        let singleItem = {
            item_id: item['id'],
            item_name: item['productdbname'],
            coupon: "",
            discount: 0,
            index: parseInt(item['id']),
            item_brand: "Ceramiche Bucci",
            // item_category: "Apparel", aggiungere la category quando pubblichi la repo
            item_variant: item['version']['name'],
            price: parseFloat(item['singleprice']),
            quantity: item['quantity']
        };
        orderItem.push(singleItem);
    });

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
            currency: "EUR",
            value: parseFloat(data['subtotal']),
            coupon: "",
            items: orderItem
        }
    });

}

export function dlOrderShipping(data) {

    let orderItem = [];

    forEach(data['items'], (item) => {
        let singleItem = {
            item_id: item['id'],
            item_name: item['productdbname'],
            coupon: "",
            discount: 0,
            index: parseInt(item['id']),
            item_brand: "Ceramiche Bucci",
            // item_category: "Apparel", aggiungere la category quando pubblichi la repo
            item_variant: item['version']['name'],
            price: parseFloat(item['singleprice']),
            quantity: item['quantity']
        };
        orderItem.push(singleItem);
    });

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
            currency: "EUR",
            value: parseFloat(data['subtotal']),
            coupon: "",
            items: orderItem
        }
    });

}

export function dlOrderComplete(data, items) {

    let orderItem = [];

    forEach(items, (item) => {
        let singleItem = {
            item_id: item['id'],
            item_name: item['productdbname'],
            coupon: "",
            discount: 0,
            index: parseInt(item['id']),
            item_brand: "Ceramiche Bucci",
            // item_category: "Apparel", aggiungere la category quando pubblichi la repo
            item_variant: item['version']['name'],
            price: parseFloat(item['singleprice']),
            quantity: item['quantity']
        };
        orderItem.push(singleItem);
    });

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "purchase",
        ecommerce: {
            transaction_id: data['code'],
            value: parseFloat(data['subtotal']),
            tax: 0,
            shipping: parseFloat(data['shippingcost']),
            currency: "EUR",
            coupon: "",
            items: orderItem
        }
    });

}