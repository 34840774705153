import "./index.scss";
import { $ } from "@olmokit/dom";

/**
 * Component: SliderCategory
 */

function slider(element) {

  let glide, anchors, breakpoints, controls, lazyLoad, swipe;

  import("@olmokit/core/glide").then(({ glide, Anchors, Breakpoints, Controls, LazyLoad, Swipe }) => {
    glide = glide;
    anchors = Anchors;
    breakpoints = Breakpoints;
    controls = Controls;
    lazyLoad = LazyLoad;
    swipe = Swipe;

    const glideOptions = {
      type: "carousel",
      perView: 1,
      startAt: 0,
      gap: 0,
      animationDuration: 400
    };

    const slider = element;

    const sliderImages = glide(slider, glideOptions);

    sliderImages.on('run', function(data) {
      const $number = $('.glide__numbers--current');
      $number.innerHTML = sliderImages.index + 1;
    })

    sliderImages.mount({
      swipe,
      lazyLoad,
      anchors,
      controls,
      breakpoints
    });

  });
}

export function SliderCategory() {
  console.log("SliderCategory mounted.");
  const element = $('.SliderCategory:container');
  slider(element);
}
