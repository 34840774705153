import { listen, getDataAttr } from "@olmokit/dom";
import { getUrlQueryParams } from "@olmokit/utils/getUrlQueryParams";
import { addFormsRedirect } from "@olmokit/core/auth/redirect";
import { globalData } from "@olmokit/core/data";
import { id, getAuthDialog } from "./index";

export default function initAuthDialog() {
  const { isGuest } = globalData?.authDialog || {};
  console.log('isGuest', isGuest);
  const queryParams = getUrlQueryParams();
  let authDialog;

  if (isGuest) {
    authDialog = getAuthDialog();

    listen("click", `[data-${id}]`, handleClick);

    if (queryParams[id]) {
      authDialog.show(queryParams[id]);
      addFormsRedirect(location.href);
    }
  }

  /**
   * Handle click, hijack auth links to auth dialog
   *
   * @param {MouseEvent} event
   */
  function handleClick(event) {
    event.preventDefault();
    authDialog.show(getDataAttr(event.target, id), event.target);
  }
}
