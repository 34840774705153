import "./index.scss";
import { replaceFragment } from "@olmokit/core/fragments";
import { Newsletter } from "components/Newsletter";

export function Footer() {
    console.log("Footer mounted.");

    replaceFragment("newsletterForm", "components.Newsletter").then(() => Newsletter());
  
}
  