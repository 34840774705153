import "./index.scss";
import { $ } from "@olmokit/dom";
import { glide, Anchors, Breakpoints, Controls, LazyLoad, Swipe } from "@olmokit/core/glide";

/**
 * Component: SliderHome
 */


function slider(element) {

  const glideOptions = {
    type: "carousel",
    rewind: false,
    perView: 1,
    startAt: 0,
    gap: 0,
    animationDuration: 400,
  };

  const slider = element;

  const sliderImages = glide(slider, glideOptions);

  sliderImages.mount({
    Swipe,
    LazyLoad,
    Anchors,
    Controls,
    Breakpoints
  });

}

export function SliderHome() {
  console.log("SliderHome mounted.");

  const sliders = $('.SliderHome:slider');

  slider(sliders);

}
