import { $ } from "@olmokit/dom";
import { mergeUrlQueryParams, removeUrlQueryParams } from "@olmokit/utils";
// import { updateUrlQueryParams } from "@olmokit/utils/updateUrlQueryParams";
import { prefillFromElement } from "@olmokit/core/olmoforms/helpers";
import { glide, Fade } from "@olmokit/core/glide";

import Dialog from "components/Dialog/Base";
// import { AuthLogin } from "components/Form/Auth/Login";
// import { AuthRegister } from "components/Form/Auth/Register";
// import { AuthPassword } from "components/Form/Auth/Password";
import "./index.scss";

/**
 * Component: DialogLogin
 */

/** dialog id */
// export const id = "dialog-login";
export const id = "auth-dialog";

let authDialog;

export function getAuthDialog() {
  if (authDialog) {
    return authDialog;
  }
  return DialogLogin();
}

/**
 * Component: Auth dialog
 */
export function DialogLogin() {

  console.log('DialogLogin mounted');

  let openAt = 0;
  const CLASS_READY = "is-ready";
  const mapIdxToForm = {
    0: "login",
    1: "register",
    2: "password-recovery",
  };
  const mapFormToIdx = swapMap(mapIdxToForm);
  const dialog = Dialog(
    ".AuthDialog:",
    { id },
    {
      opening: handleOpening,
      opened: handleOpen,
      closed: handleClosed,
    }
  );
  const sliderForms = glide($(".AuthDialog:forms"), {
    loop: false,
    animationDuration: 180,
  });

  sliderForms.on("mount.after", handleSliderMounted).on("run", handleSliderRun);

  /**
   * Handle slider after mount
   */
  function handleSliderMounted() {
    dialog.$root.classList.add(CLASS_READY);
  }

  /**
   * Handle slider run
   */
  function handleSliderRun() {
    udpdateDeeplink(mapIdxToForm[sliderForms.index]);    
  }

  /**
   * Update url parameter for deeplinking
   *
   * @param {AuthDialog.formType} [form]
   */
  function udpdateDeeplink(form) {
    if (form) {
      mergeUrlQueryParams({ [id]: form }, true);
      // FIXME: should update the redirect in the form?
    } else {
      removeUrlQueryParams(id, true);
    }
  }

  /**
   * Handle opening, dynamically import the forms assets while opening the
   * dialog
   */
  function handleOpening() {
    import("components/Form/Auth/Login").then(({ AuthLogin }) => AuthLogin());
    import("components/Form/Auth/Register").then(({ AuthRegister }) =>
      AuthRegister()
    );
    import("components/Form/Auth/Password").then(({ AuthPassword }) =>
      AuthPassword()
    );
  }

  /**
   * Handle dialog open
   */
  function handleOpen() {
    sliderForms.mount({
      Fade
    });
  }

  /**
   * Handle dialog close
   */
  function handleClosed() {
    dialog.$root.classList.remove(CLASS_READY);
    udpdateDeeplink();
  }

  function swapMap(map = {}) {
    const output = {};
    for (const key in map) {
      output[map[key]] = key;
    }
    return output;
  }

  /**
   * Show
   *
   * A dialog instance decorator to open the dialog with a specific form
   * initially visible
   *
   * @param {AuthDialog.formType} form Which form to show
   * @param {HTMLElement | Document} [trigger]
   */
  function show(form, trigger) {
    openAt = mapFormToIdx[form] || 0;

    if (trigger) prefillFromElement(trigger, dialog.$root);

    if (dialog.opened) {
      sliderForms.go(`=${openAt}`);
    } else {
      sliderForms.update({ startAt: openAt });
      dialog.open();
      udpdateDeeplink(form);
    }
  }

  // create instance and assign it for later reuse
  authDialog = {
    ...dialog,
    show,
  };

  return authDialog;
}