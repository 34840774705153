import "./Block.scss";
import { ProductCard } from "components/Product";
import { $all, forEach, addClass, removeClass } from "@olmokit/dom";
import { globalData } from "@olmokit/core/data";

/**
 * Component: ProductBlock
 */

function slider(element) {

  let glide, anchors, breakpoints, controls, lazyLoad, swipe;

  import("@olmokit/core/glide").then(({ glide, Anchors, Breakpoints, Controls, LazyLoad, Swipe }) => {
    glide = glide;
    anchors = Anchors;
    breakpoints = Breakpoints;
    controls = Controls;
    lazyLoad = LazyLoad;
    swipe = Swipe;

    const glideOptions = {
      type: "carousel",
      // rewind: false,
      perView: 3,
      // loop: false,
      startAt: 0,
      gap: 0,
      animationDuration: 400,
      breakpoints: {
        1024: {
          perView: 2,
          startAt: 0,
          gap: 15,
          peek: {
            before: 0,
            after: 0
          },
        },
        600: {
          perView: 1,
          gap: 0,
          peek: {
            before: 0,
            after: 0
          }
        }
      }
    };

    const slider = element;

    const sliderImages = glide(slider, glideOptions);

    sliderImages.mount({
      swipe,
      lazyLoad,
      anchors,
      controls,
      breakpoints
    });

  });
}

export function ProductBlock() {
  console.log("ProductBlock mounted.");
  ProductCard();

  const sliders = $all('.ProductBlock:container');
  const arrow = $all('.glide__arrows');
  forEach(sliders, (slide, index) => {
    const slideIndex = globalData['sliderProduct-' + index];
    const width = window.innerWidth;

    const $cards = $all('.ProductBlock:card', slide);
    if (width > 1024 && slideIndex > 3) {
      console.log('index 3', index+' - '+slideIndex);
      forEach($cards, (card) => {
        addClass(card, 'deleteCol');
        addClass(card, 'deleteRow');
      });
      slider(slide);
    } else if (width <= 1024 && width > 600 && slideIndex > 2) {
      console.log('index 2', index+' - '+slideIndex);
      forEach($cards, (card) => {
        addClass(card, 'deleteCol');
        addClass(card, 'deleteRow');
      });   
      slider(slide);
    } else if (width < 600 && slideIndex > 1) {
      console.log('index 1', index+' - '+slideIndex);
      forEach($cards, (card) => {
        addClass(card, 'deleteCol');
        addClass(card, 'deleteRow');
      });    
      slider(slide);
    } else {
      console.log('index else', index+' - '+slideIndex);
      addClass(arrow[index+1], 'deleteArrows');  
    }

  });

}
