import "@olmokit/core/debug/api";
import "@olmokit/core/reboot/index.scss";
import "@olmokit/core/grid/index.scss";
import "@olmokit/core/typography/reset.scss";
import "@olmokit/core/icon/index.scss";
import LazyLoad from "@olmokit/core/lazy";
import "@olmokit/core/img";
import { Header } from "components/Header";
import "components/Footer";
import "./index.scss";
import "utils/btn.scss";
import "utils/fonts.scss";
import "utils/logo.scss";
import "components/Hamburger";
import { Button } from "components/Button";
// import { MenuMobile } from "components/MenuMobile";
import { Footer } from "components/Footer";
import initAuthDialog from "components/Dialog/Login/init";

Header();
Button();
// MenuMobile();
Footer();
initAuthDialog();

import("components/MenuMobile").then(({ MenuMobile }) => {
    MenuMobile();
});

new LazyLoad();
