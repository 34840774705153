import "./Card.scss";
import { $, $all, forEach, on, addClass, removeClass } from "@olmokit/dom";
import { WishlistBtn } from "components/Wishlist/Btn";
import { initWishlist, wishlistOn } from "@olmokit/core/wishlist";
import { dlSelectItem, dlAddWishlist } from "components/Datalayer";

/**
 * Component: ProductCard
 */

export function ProductCard() {
  console.log("ProductCard mounted.");

  // WishlistBtn();
  // initWishlist();

  /**
   * Riscrivere la risposta della wiswhlist in modo da passare i dati al datalayer
   */
  wishlistOn("add:ok", (data) => {
    // dlAddWishlist();
  });

  /**
   * Questo non va
   */
  wishlistOn("remove:ok", (data) => {
    // dlAddWishlist();
  });

  const $card = $all('.ProductCard:');
  
  forEach($card, (card) => {
    const $swatches = $all('.ProductCard:bottom-swatches_dots', card);
    const $image = $all('.ProductCard:image', card);
    forEach($swatches, (swatch, index) => {
      on(swatch, 'click', () => {
        forEach($swatches, (s, i) => {
          removeClass(s, 'is-color');
          removeClass($image[i], 'is-image');
        });

        addClass($image[index], 'is-image');

        addClass(swatch, 'is-color');

      })   
    });
    on(card, 'click', () => {
      const properties = card.getAttribute('data-properties');
      const name = card.getAttribute('data-name');
      const category = card.getAttribute('data-category');
      const price = card.getAttribute('data-price');
      const id = card.getAttribute('data-id');
      const a = $('a', card).getAttribute('href');
      dlSelectItem(properties, name, category, price, id, a);
    });    
  });

}
